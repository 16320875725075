<template>
  <v-layout column justify-center align-center>
    <v-flex xs12 sm8 md6>
      <v-card width="400px">
        <v-card-title class="headline">Add campaign to user</v-card-title>
        <v-card-text>
          {{ user.email }}
          <form>
            <v-text-field v-model="fullName" :value="fullName" label="Name" />
            <v-select
              v-model="campaign"
              :items="forms"
              label="Campaign"
              item-text="formName"
              item-value="formId"
              return-object
            ></v-select>
            <v-btn @click="updateUser(user._id)">Update user</v-btn>
          </form>
        </v-card-text>
      </v-card>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">Campaigns</th>
            </tr>
          </thead>
          <tbody>
            <tr
              style="justify-content: space-between"
              v-for="u in user.campaigns"
              :key="u.name"
            >
              <td>{{ u.name }}</td>
              <td>
                <v-btn
                  style="align-self:right"
                  class="mx-1"
                  raised
                  small
                  color="error"
                  @click="deleteCampaign(u.id, user._id)"
                  >Delete</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-flex>
    <div v-if="feedback">{{ feedback }}</div>
  </v-layout>
</template>

<script>
import axios from "axios";

export default {
  name: "editUser",
  data() {
    return {
      feedback: "",
      user: {},
      forms: [],
      campaign: null,
      fullName: "",
    };
  },
  computed: {
    uniqueForms() {
      return this.forms.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.formId]: current,
        });
      }, {});
    },
  },
  async mounted() {
    try {
      const { data } = await axios.get("/api/form/");
      this.forms = data;
      this.forms = [].concat(this.forms).reverse();
      this.getUserInfo();
    } catch (error) {
      this.feedback = error;
    }
  },
  methods: {
    async getUserInfo() {
      try {
        const { data } = await axios.get(
          "/api/admin/" + this.$route.params.user
        );
        this.fullName = data.fullName;
        this.user = data;
      } catch (error) {
        this.feedback = error;
      }
    },
    async deleteCampaign(campaign, id) {
      let deletedCampaign = {
        campaignId: campaign,
      };
      try {
        if (confirm("Are you sure?")) {
          await axios.put(`/api/admin/delCampaign/${id}`, deletedCampaign);
          await this.getUserInfo();
        }
      } catch (error) {
        this.feedback = error;
      }
    },
    async updateUser(id) {
      try {
        let data = {
          campaigns: [],
        };
        if (this.campaign) {
          data.campaigns.push({
            id: this.campaign.formId,
            name: this.campaign.formName,
          });
        }

        await axios.put(`/api/admin/${id}`, {
          campaigns: data.campaigns,
          fullName: this.fullName,
        });
        this.$router.push({ name: "admin" });
      } catch (error) {
        this.feedback = error;
      }
    },
  },
};
</script>
